import React, { ReactElement, FC } from "react"
import { Row, Col } from "antd"
import ReactMarkdown from "react-markdown"

import "./post-content.less"

interface I_Props {
  content: string
}

const PostContentComponent: FC<I_Props> = ({
  content,
}: I_Props): ReactElement => {
  return (
    <Row justify="center" className="post-content">
      <Col xs={24} lg={18}>
        <ReactMarkdown children={content} />
      </Col>
    </Row>
  )
}

export default PostContentComponent
