import React, { ReactElement, FC } from "react"
import { Row, Col, Typography } from "antd"
import ReactMarkdown from "react-markdown"

import "./hero-post.less"
import { I_Image } from "../../global/interfaces"

const { Title } = Typography

interface I_Props {
  title: string
  intro: string
  cover: I_Image
}

const HeroPostComponent: FC<I_Props> = ({
  title,
  intro,
  cover,
}: I_Props): ReactElement => {
  return (
    <Row justify="center" className="hero-post">
      <Col xs={24} lg={18}>
        <Title className="hero-post__title">{title}</Title>
        <ReactMarkdown className="hero-post__intro" children={intro} />
      </Col>
      <Col xs={0} lg={24} className="hero-post__image">
        <img src={cover.url} alt={intro} width="100%" />
      </Col>
      <Col xs={24} lg={0}>
        <img src={cover.url} alt={intro} width="100%" />
      </Col>
    </Row>
  )
}

export default HeroPostComponent
